import React from "react"
import { Link } from "gatsby"

import Layout from "../../../../components/layout"
import SEO from "../../../../components/seo"

function HobbitonIndex(props) {
  return (
    <>
      <SEO title="Hobbiton Movie Set Tours | Hobbiton Tours - awesomeNZ" />
      <Layout
        layoutType="typography TourHolderPage"
        slideshow={
          <ul class="slideshow-slides">
            <li class="slideshow-slide first">
              <img
                src={require("../../../../images/sliders/hobbiton-banner-1.jpg")}
                alt=""
                class="first image"
              />
            </li>
          </ul>
        }
        hasSliderHandles={false}
        hasSliderBlurSmall={true}
        content={
          <>
            <div id="TourHolderPage" class="clearfix">
              <div class="title-div">
                <h1>Hobbiton</h1>
                <h3>
                  See the intricately built Hobbiton movie set on picturesque
                  Matamata farmland
                </h3>
                <Link
                  class="alltours"
                  to="/home/new-zealand-tours/hobbiton-tours#tours-section"
                >
                  <p>View all Hobbiton tours &gt;</p>
                </Link>
              </div>
              <div class="destination-content">
                {/* ... */}
                <p>
                  Tucked away on picturesque private farmland near Matamata in
                  the North Island's Waikato region is the Hobbiton Movie Set.
                  Visitors come from around the world to see this fascinating
                  attraction, where director Sir Peter Jackson and his team
                  recreated a little piece of Tolkien's Middle-earth. Take a
                  guided tour and step into a world of hobbits, elves, wizards
                  and dwarves, where the silver screen meets reality!
                </p>
                {/* ... */}
              </div>
              <div id="tours-section" class="row">
                <div class="tour-item six columns tag-tour first-tour">
                  <h3>Our most popular tour</h3>
                  <div class="tour-image">
                    <Link
                      to="/home/new-zealand-tours/hobbiton-tours/hobbiton-movie-set-tour-from-auckland"
                      class="tour-pic"
                    >
                      <img
                        src={require("../../../../images/tours/awesomenz-hobbiton-tour.png")}
                        alt="Click here to find out more about this tour"
                        width="172"
                        height="126"
                      />
                    </Link>
                  </div>
                  <div class="tour-details">
                    <h2>Hobbiton Movie Set Tour From Auckland</h2>
                    <div class="tour-details-prices">
                      <span class="tour-details-prices-from">From </span>
                      <span class="tour-details-prices-price">$195.00</span>
                    </div>
                    <span class="clearfix">
                      <Link to="/home/new-zealand-tours/hobbiton-tours/hobbiton-movie-set-tour-from-auckland">
                        Find out more &gt;
                      </Link>
                    </span>
                  </div>
                  <div class="clear"></div>
                </div>

                <div class="tour-item six columns tag-tour">
                  <div class="tour-image">
                    <Link
                      to="/home/new-zealand-tours/hobbiton-tours/hobbiton-movie-set-tour-from-auckland"
                      class="tour-pic"
                    >
                      <img
                        src={require("../../../../images/tours/awesomenz-hobbiton-tour.png")}
                        alt="Click here to find out more about this tour"
                        width="172"
                        height="126"
                      />
                    </Link>
                  </div>
                  <div class="tour-details">
                    <h2>Hobbiton Movie Set Tour From Auckland</h2>
                    <div class="tour-details-prices">
                      <span class="tour-details-prices-from">From </span>
                      <span class="tour-details-prices-price">$195.00</span>
                    </div>
                    <span class="clearfix">
                      <Link to="/home/new-zealand-tours/hobbiton-movies/hobbiton-movie-set-tour-from-auckland">
                        Find out more &gt;
                      </Link>
                    </span>
                  </div>
                  <div class="clear"></div>
                </div>

                <div class="tour-item six columns tag-tour">
                  <div class="tour-image">
                    <Link
                      to="/home/new-zealand-tours/hobbiton-tours/auckland-matamata-return-transfer"
                      class="tour-pic"
                    >
                      <img
                        src={require("../../../../images/tours/awesomenz-hobbiton-coach-transfer.png")}
                        alt="Click here to find out more about this tour"
                        width="172"
                        height="126"
                      />
                    </Link>
                  </div>
                  <div class="tour-details">
                    <h2>
                      Auckland-Matamata Return Coach Transfer For Hobbiton Movie
                      Set
                    </h2>
                    <div class="tour-details-prices">
                      <span class="tour-details-prices-from">From </span>{" "}
                      <span class="tour-details-prices-price">$115.00</span>
                    </div>
                    <span class="clearfix">
                      <Link to="/home/new-zealand-tours/hobbiton-tours/auckland-matamata-return-transfer">
                        Find out more &gt;
                      </Link>
                    </span>
                  </div>
                  <div class="clear"></div>
                </div>
              </div>
            </div>
          </>
        }
      />
    </>
  )
}

export default HobbitonIndex
